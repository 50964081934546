// 离职员工列表
<template>
    <div class="">
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="姓名"
                              prop="userName">
                    <el-input v-model="searchForm.userName"
                              placeholder="请输入姓名"
                              clearable />
                </el-form-item>

                <el-form-item label="部门"
                              prop="deptName">
                    <el-input v-model="searchForm.deptName"
                              placeholder="请输入部门"
                              clearable />
                </el-form-item>

                <el-form-item label="职位"
                              prop="job">
                    <el-input v-model="searchForm.job"
                              placeholder="请输入姓名"
                              clearable />
                </el-form-item>

                <el-form-item label="离职日期"
                              prop="date">
                    <el-date-picker v-model="searchForm.date"
                                    type="daterange"
                                    range-separator="至"
                                    value-format="yyyy-MM-dd"
                                    format="yyyy-MM-dd"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary"
                               @click="search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData"
                          border
                          v-loading="isloading"
                          :height="tableHeight"
                          stripe>
                    <el-table-column type="index"
                                     width="50"
                                     label="序号">
                    </el-table-column>
                    <el-table-column prop="userName"
                                     width="200"
                                     label="姓名">
                    </el-table-column>
                    <el-table-column prop="deptName"
                                     width="200"
                                     label="部门">
                    </el-table-column>
                    <el-table-column prop="job"
                                     width="180"
                                     label="职位">
                    </el-table-column>
                    <el-table-column prop="leaveDate"
                                     width="140"
                                     label="离职日期">
                    </el-table-column>
                    <el-table-column prop="leaveReasonType"
                                     width="140"
                                     label="离职原因类型">
                    </el-table-column>

                    <el-table-column prop="leavePerson"
                                     width="110"
                                     label="离职交接人">
                        <template slot-scope="scope">
                            {{scope.row.leavePerson || "无"}}
                        </template>
                    </el-table-column>

                    <el-table-column prop="leaveReasonRemark"
                                     min-width="150"
                                     label="离职原因备注">
                    </el-table-column>

                    <el-table-column label="操作"
                                     fixed="right"
                                     width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="details(scope.row)">详情</el-link>
                        </template>
                    </el-table-column>

                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
    </div>
</template>

<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "departureList",
    props: {
        //人员状态
        type: {
            type: String,
            default: "",
        },
        //触发更新
        refresh: {
            type: Number,
        },
    },

    watch: {
        refresh: {
            deep: true,
            immediate: true,
            handler: function (newVal) {
                newVal && this.getList();
            },
        },
    },

    components: {
        Pagination,
    },

    data() {
        return {
            dialogVisible: false,
            searchForm: {
                userName: "", //姓名
                job: "", //职位
                deptName: "", //部门名称
                date: ["", ""], //离职日期
                page: 1,
                size: 20,
            },
            pageTotal: 0,
            isloading: false,
            rowData: null,
            tableData: [{ id: 2 }],
        };
    },

    created() {},

    mounted() {
        this.search();
    },

    methods: {
        /* 获取列表 */
        getList(searchForm = this.searchForm) {
            this.isloading = true;
            humanResourcesManageApi
                .getOnlineUser(
                    Object.assign({}, searchForm, {
                        leaveStartDate: searchForm.date[0],
                        leaveEndDate: searchForm.date[1],
                        workerStatuses: this.type.split(","),
                    })
                )
                .then((res) => {
                    this.isloading = false;
                    this.tableData = res.content.records;
                    this.pageTotal = res.content.total;
                    this.$emit("contractTypeNum", {
                        type: this.type,
                        num: this.pageTotal,
                    }); //设置总数
                })
                .catch(() => {
                    this.isloading = false;
                });
        },

        //查看详情
        details(e) {
            this.goDetrail({
                data: JSON.stringify(Object.assign({ userType: "level" }, e)),
            });
        },

        //前往详情页
        goDetrail(data) {
            this.$router.push({
                path: "/humanResourcesManage/detailContainer",
                query: data,
            });
        },
        // 搜索
        search() {
            this.defaultSearch();
        },

        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    // 查询列表
                    this.getList();
                }
            });
        },

        // 分页改变
        paginationChange(e) {
            this.getList(Object.assign(this.searchForm, e));
        },

        // 默认查询第一页
        defaultSearch() {
            this.searchForm.page = 1;
            this.getList();
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 340 });
        },
    },
};
</script>

<style lang='scss' scoped>
</style>
