// 在职员工管理
<template>
    <div class="baseBg reserveProject">
        <div class="listBox mainWhiteBg"
             style="position: relative;">
            <ayncOrExport @ayncSuccess="ayncSuccess"
                          :isLeaving="true"></ayncOrExport>
            <el-tabs v-model="activeName"
                     ref="searchTab"
                     class="searchTab">
                <el-tab-pane :name="item.type"
                             v-for="(item,index) in tabList"
                             :key="index">
                    <div class="tabsLabel"
                         slot="label">
                        <el-badge :type="activeName == item.type ? 'primary' : 'warning'"
                                  :value="item.value"
                                  class="item">
                            {{item.title}}
                        </el-badge>
                    </div>
                    <departureList :type="item.type"
                                   :refresh="refresh"
                                   @contractTypeNum="setContractTypeNum"></departureList>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>

</template>

<script>
import ayncOrExport from "../components/ayncOrExport";
import departureList from "./list";
export default {
    name: "departureIndex",
    props: [],

    components: {
        // Pagination,
        departureList,
        ayncOrExport,
    },

    data() {
        return {
            activeName: "LEAVE",
            dialogVisible: false,
            tabList: [
                {
                    type: "LEAVE",
                    value: 0,
                    title: "离职员工",
                },
            ], //员工状态tab
            refresh: 0, //同步数据后需要更新  不为0就更新
        };
    },

    created() {},

    mounted() {},

    methods: {
        //设置各类型数量
        setContractTypeNum(data) {
            this.tabList = this.tabList.map((v) => {
                if (v.type === data.type) {
                    v.value = v.value === 0 ? data.num : v.value;
                }
                return v;
            });
        },

        //员工花名册同步成功
        ayncSuccess() {
            this.refresh = Math.random();
            this.$forceUpdate();
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.reserveProject ::v-deep .el-tabs__nav-scroll {
    overflow: visible;
}
.reserveProject ::v-deep .el-tabs__nav-wrap {
    overflow: visible;
    height: 40px;
}
.topBtn {
    // float: right;
    // // margin: 0 0px 20px 20px;
    // margin:  0 0px 0 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}
.reserveProject .topBtn:nth-child(1) {
    right: 140px;
}
</style>
