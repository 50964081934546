// 在职员工管理
<template>
    <div class="reserveProject_">
        <el-button type="success topBtn"
                   @click="asyncData"
                   :disabled="isloading"
                   v-loading="isloading">同步数据</el-button>
        <el-button type="primary topBtn"
                   :disabled="exportLoading"
                   v-loading="exportLoading"
                   @click="exportFile()">导出花名册</el-button>
    </div>
</template>

<script>
import humanResourcesManageApi from "@/api/humanResourcesManageApi.js";
import axios from "axios";
export default {
    name: "ayncOrExport",
    props: {
        //是否已离职
        isLeaving: {},
    },

    components: {},

    data() {
        return {
            isloading: false, //同步
            exportLoading: false, //导出
        };
    },

    created() {},

    mounted() {},

    methods: {
        //员工花名册同步
        asyncData() {
            this.isloading = true;
            axios.defaults.timeout = 600000;
            humanResourcesManageApi
                .synchronizeData()
                .then((res) => {
                    this.isloading = false;
                    this.$message({
                        message:
                            res.message || "恭喜！员工花名册已同步至最新。",
                        type: "success",
                    });
                    this.$emit("ayncSuccess");
                })
                .catch(() => {
                    this.isloading = false;
                })
                .finally(() => (axios.defaults.timeout = 10000));
        },

        //导出花名册
        exportFile() {
            this.exportLoading = true;
            // window.open(`${window.configPath.baseUrl}/user/user-expand/exportData/${this.isLeaving}`);
            this.commonJs
                .fileDownLoad(
                    {
                        name: this.isLeaving
                            ? "离职员工花名册.xlsx"
                            : "在职员工花名册.xlsx",
                    },
                    `/user/user-expand/exportData/${this.isLeaving}`
                )
                .then(() => {
                    this.exportLoading = false;
                });
            // this.commonJs
            //     .fileDownLoad("", `/user/user-expand/exportData/${this.isLeaving}`)
            //     .then(() => {
            //         this.exportLoading = false;
            //     });
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang='scss' scoped>
.topBtn {
    // float: right;
    // // margin: 0 0px 20px 20px;
    // margin:  0 0px 0 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9;
}
.reserveProject_ .topBtn:nth-child(1) {
    right: 140px;
}
</style>
